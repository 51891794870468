<template>
  <UiDialog ref="dialog" title="Provide your email to proceed" hideCloseButton>
    <UiForm @submit="submit()">
      <UiLayout gapSm>
        <UiLayout>
          <UiTextInput required fullWidth v-model="email" :type="'email'" label="Email address" placeholder="Enter your email" />
        </UiLayout>
        <UiLayout horizontal gapSm justifyEnd>
          <UiButton submit primary>Continue</UiButton>
          <UiButton @click="cancel()">Cancel</UiButton>
        </UiLayout>
      </UiLayout>
    </UiForm>
  </UiDialog>
</template>
<script lang="ts" setup>
import { ref, watch } from "vue";
import { injectEmailCollectionController } from "@/app-utils";
import { UiDialog, UiTextInput, UiLayout, UiButton, UiForm } from "@/components/ui";

// This form is trigged automatically when you call
// the `requestUserEmail` function. It's meant to collect emails
// ad-hoc from users who are not logged in, in operations that
// require an email address (e.g. paying with Paystack).


const dialog = ref<typeof UiDialog>();
const email = ref<string>();

const controller = injectEmailCollectionController();
const openSignal = controller?.getOpenDialogSignal() || ref();

watch(openSignal, open);

function open() {
  email.value = undefined;
  dialog.value?.open();
}

function submit() {
  controller?.submitCollection(email.value);
  dialog.value?.close();
}

function cancel() {
  controller?.submitCollection(undefined);
  dialog.value?.close();
}


</script>