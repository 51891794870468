import { createRouter, createWebHistory } from 'vue-router'
import { registerRouterNavigationHooks } from './hooks';
import { logger } from '@/app-utils';

const HomeView = () => import('../views/HomeView.vue');
const DownloadView = () => import('../views/DownloadView.vue');
const UploadView = () => import('../views/UploadView.vue');
const PaymentView = () => import('../views/PaymentView.vue');
const TransactionView = () => import('../views/TransactionView.vue');
const SettingsView = () => import('@/views/settings/SettingsView.vue');
const BillingView = () => import('@/views/settings/BillingView.vue');
const PlayerView = () => import('@/views/PlayerView.vue');
const CompareVersionsView = () => import('@/views/CompareVersionsView.vue');
const TransfersView = () => import('@/views/TransfersView.vue');
const AppView = () => import('@/views/AppView.vue');
const ProjectsView = () => import('@/views/ProjectsView.vue');
const ProjectView = () => import('@/views/ProjectView.vue');
const ProjectMediaView = () => import('@/views/ProjectMediaView.vue');
const ProjectMembersView = () => import('@/views/ProjectMembersView.vue');
const ProjectReviewLinksView = () => import('@/views/ProjectReviewLinksView.vue');
const ProjectSettingsView = () => import('@/views/project-settings/ProjectSettingsView.vue');
const InviteView = () => import('@/views/InviteView.vue');
const LoginView = () => import('@/views/auth/LoginView.vue');
const SignupView = () => import('@/views/auth/SignupView.vue');
const PasswordResetView = () => import('@/views/auth/PasswordResetView.vue');
const ProjectShareView = () => import('@/views/ProjectShareView.vue');
const ProjectShareListView = () => import('@/views/ProjectShareListView.vue');
const ProjectSharePlayerView = () => import('@/views/ProjectSharePlayerView.vue');
const ProjectShareCompareVersionsView = () => import('@/views/ProjectShareCompareVersionsView.vue');
const DesktopTransferView = () => import('@/views/DesktopTransfer.vue');
const AccountUsageView = () => import('@/views/settings/AccountUsageView.vue');
const ProjectActivityView = () => import('@/views/ProjectActivityView.vue');
const ProjectTransferView = () => import('@/views/ProjectTransferView.vue');

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      children: [
        {
          path: '',
          name: 'appHome',
          component: AppView,
          children: [
            {
              path: '',
              name: 'projects',
              component: ProjectsView
            },
            {
              path: 'upload',
              name: 'upload',
              component: UploadView
            },
            {
              path: 'pay',
              name: 'pay',
              component: PaymentView
            },
            {
              path: 'transaction/:transactionId',
              name: 'transaction',
              component: TransactionView
            },
            {
              path: 'transfers',
              name: 'transfers',
              component: TransfersView
            },
            {
              path: 'projects/:projectId',
              name: 'project',
              component: ProjectView,
              children: [
                {
                  path: ':folderId?',
                  name: 'project-media',
                  component: ProjectMediaView
                },
                {
                  path: 'activity',
                  name: 'project-activity',
                  component: ProjectActivityView
                },
                {
                  path: 'members',
                  name: 'project-members',
                  component: ProjectMembersView
                },
                {
                  path: 'shared-links',
                  name: 'project-shared-links',
                  component: ProjectReviewLinksView
                },
                {
                  path: 'settings',
                  name: 'project-settings',
                  component: ProjectSettingsView
                },
                {
                  path: 'transfers/:transferId',
                  name: 'project-transfer',
                  component: ProjectTransferView
                }
              ]
            },
            {
              path: 'projects/:projectId/player/:mediaId',
              name: 'player',
              component: PlayerView
            },
            {
              path: 'projects/:projectId/compare/:mediaId',
              name: 'compare-versions',
              component: CompareVersionsView
            },
            {
              path: 'settings',
              name: 'settings',
              component: SettingsView,
              children: [
                {
                  path: 'accounts/:accountId',
                  name: 'account-settings',
                  children: [
                    {
                      path: 'billing',
                      name: 'billing',
                      component: BillingView
                    },
                    {
                      path: 'usage',
                      name: 'account-usage',
                      component: AccountUsageView
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/auth/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/auth/signup',
      name: 'signup',
      component: SignupView
    },
    {
      path: '/auth/password-reset',
      name: 'password-reset',
      component: PasswordResetView
    },
    {
      path: '/d/:downloadId',
      name: 'download',
      component: DownloadView
    },
    {
      path: '/i/:inviteId',
      name: 'invite',
      component: InviteView
    },
    {
      path: '/share/:shareId/:code',
      component: ProjectShareView,
      children: [
        {
          path: ':folderId?',
          name: 'project-share',
          component: ProjectShareListView
        },
        {
          path: 'player/:mediaId',
          name: 'project-share-player',
          component: ProjectSharePlayerView
        },
        {
          path: 'compare/:mediaId',
          name: 'project-share-compare-versions',
          component: ProjectShareCompareVersionsView
        }
      ]
    },
    {
      path: '/desktop-transfer',
      name: 'desktop-transfer',
      component: DesktopTransferView
    },
    {
      path: '/guest-user-post-payment',
      name: 'guest-user-post-payment',
      component: () => import('@/views/GuestUserPostPaymentView.vue')
    },
    {
      path: '/onboard',
      name: 'onboarding',
      component: () => import('@/views/onboarding/OnboardingView.vue')
    },
  ]
});

registerRouterNavigationHooks(router);

router.onError((error, to) => {
  // When we deploy a new version, users may experience errors when trying to navigate to some
  // pages because of browser cache issues related to dynamically imported modules.
  // See: https://stackoverflow.com/questions/69300341/typeerror-failed-to-fetch-dynamically-imported-module-on-vue-vite-vanilla-set
  logger?.error('Router error', error);
  if (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes("Importing a module script failed") && to) {
    window.location.href = to.fullPath
    logger?.warn(`Redirected user to ${to.fullPath} to after router module import error`);
  }
});

export { router }
