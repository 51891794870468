import { provide, inject, ref, computed, type InjectionKey } from 'vue'
import { logger } from "./logger";

const key = Symbol() as InjectionKey<AdhocEmailCollectionController>;

class AdhocEmailCollectionController {
    private currentResolveFn: ((email: string|undefined) => void) | undefined = undefined;
    private formDialogOpenSignal = ref<any>(1);

    startNewCollection() {
        if (this.currentResolveFn) {
            logger?.warn("Detecting unresolved email collection while attempting to start a new one. This could lead to unintended or undefined behaviour, e.g. promise that hangs forever.");

            // cancel to avoid issues;
            return Promise.resolve(undefined);
        }


        const promise = new Promise<string|undefined>((resolve) => {
            this.currentResolveFn = resolve;
        });

        this.openFormDialog();

        return promise;
    }

    /**
     * Get the signal to watch to know when to open the email collection form dialog.
     * The target dialog should open when ever this signal changes, regardless of the actual value.
     * @returns 
     */
    getOpenDialogSignal = () => computed(() => this.formDialogOpenSignal.value);

    submitCollection(email: string|undefined) {
        if (!this.currentResolveFn) {
            logger?.warn("Detecting email collection submission without an active email collection promise. This indicates incorrect application state and could lead to undefined behaviour.");
            return;
        }

        this.currentResolveFn(email);
        this.currentResolveFn = undefined;
    }

    private openFormDialog() {
        this.formDialogOpenSignal.value = (this.formDialogOpenSignal.value + 1) % 2;
    }
}

// We use a singleton to ensure it can be accessed from anywhere, including outside a component's flow.
// We assume only one request for email collection will be made at a time.

const emailCollectionController = new AdhocEmailCollectionController();

export function provideEmailCollectionController() {
    provide(key, emailCollectionController);
    return emailCollectionController;
}

export function injectEmailCollectionController() {
    return inject(key);
}

export function requestUserEmail(): Promise<string|undefined> {
    return emailCollectionController.startNewCollection();
}