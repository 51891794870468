import { ref, reactive, type Ref, type UnwrapRef } from "vue";
import type { CreateTransferResult } from "@quickbyte/common";
import type { ZipDownloadRequestFile } from "./downloader";

export class TaskManager {
    private tasks: Ref<Task<TaskData>[]>;
    private nextId = 1;

    constructor() {
        this.tasks = ref([]);
    }

    createTask<T extends TaskData>(description: string, task: T) : Task<UnwrapRef<T>> {
        const reactiveTask = reactive<Task<T>>({
            _id: this.generateId(),
            data: task,
            status: 'pending',
            description
        });

        this.tasks.value.push(reactiveTask);
        return reactiveTask;
    }

    removeTask(id: string) {
        this.tasks.value = this.tasks.value.filter(t => t._id !== id);
    }

    generateId(): string {
        return `${this.nextId++}`;
    }

    getTasks() {
        return this.tasks;
    }

    /**
     * Removes all tasks from the task manager.
     */
    clearTasks() {
        this.tasks.value = [];
    }
}

export interface BaseTask {
    _id: string;
    description: string;
    status: TaskStatus;
    progress?: number;
    error?: string;
}

export type TaskStatus = 'pending'|'progress'|'complete'|'error'|'cancelled';

export interface TransferTask {
    type: 'transfer',
    transfer?: CreateTransferResult
    /**
     * The total bytes uploaded for this transfer so far.
     */
    totalUploaded?: number;
    /**
     * Map of file id to the number of bytes uploaded for that file so far.
     */
    uploadedPerFile: Map<string, number>;
}

export interface DownloadTask {
    type: 'zipDownload';
    files: ZipDownloadRequestFile[];
    downloadFileName?: string;
    totalDownloaded?: number;
    totalSize: number;
}

export interface AnonymousUseUpgradeTask {
    type: 'anonymousUserUpgrade';
}

export interface RecoveredUploadsDetectedTask {
    type: 'recoveredUploadsDetected';
}

export interface RecommendDesktopAppForUploadTask {
    type: 'recommendDesktopAppForUpload'
}

export interface Task<T> extends BaseTask {
    data: T;
}

export type TaskData =
    TransferTask
    | DownloadTask
    | AnonymousUseUpgradeTask
    | RecoveredUploadsDetectedTask
    | RecommendDesktopAppForUploadTask;
