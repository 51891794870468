<template>
  <router-view />
  <Toast />
  <VueQueryDevtools />
  <CollectUserEmailForm />
</template>

<script setup lang='ts'>
import { onMounted, watch } from 'vue';
import { auth, getDeviceData, hideSupportChatButton, onChartWidgetReady, provideEmailCollectionController } from "@/app-utils";
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import CollectUserEmailForm from './components/email-collection/CollectUserEmailForm.vue';
// AOS stands for Animate On Scroll, is a library that adds cool animations to elements as you scroll down a webpage.
import AOS from 'aos';
import Toast from '@/components/Toast.vue';

// This allows a user collection form to be triggered from anywhere
// in the app by calling the `requestUserEmail` function.
provideEmailCollectionController();
const authenticated = auth.isAuthenticated();

onMounted(async () => {
  AOS.init();
  auth.init();
  
  getDeviceData();

  // Hide chat widget by default.
  /*
   * My goal was to show the chat button when logged
   * out and hide it when logged in (since it can
   * be annoying and also cover important UI elements).
   * 
   * However, once you reveal the button, calls
   * to hide it seem to be ignore by zoho,
   * so for now, I'm just hiding it by default until
   * I figure out how to properly toggle it on and off reliably.
   * 
   */
  onChartWidgetReady(() => hideSupportChatButton())
});
</script>
